import store from '@/store'
import { computed } from '@vue/composition-api'

export const useTickets = () => {

  const submitValidatedTicket = (ticket) => {
    return new Promise((resolve, reject) => {
      if ('id' in ticket) {
        updateTicket(ticket)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      } else {
        saveNewTicket(ticket)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  }

  const saveNewTicket = (ticket) => {
    return new Promise((resolve, reject) => {
      store.dispatch('ticket/addTicket', ticket)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const updateTicket = (ticket) => {
    return new Promise((resolve, reject) => {
      store.dispatch('ticket/updateTicket', ticket)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const nextStep = (ticket) => {
    return new Promise((resolve, reject) => {
      if (ticket.status == 'New' || ticket.status == 'Cancelled' ) {
        ticket.status = 'In progress'
        submitValidatedTicket(ticket).then(response => {
          resolve(response)
        })
      } else if (ticket.status == 'In progress') {
        ticket.status = 'Done'
        submitValidatedTicket(ticket).then(response => {
          resolve(response)
        })
      }
      else {
        reject()
      }
    })

  }

  const cancelTicket = (ticket) => {
    return new Promise((resolve, reject) => {
      ticket.status = 'Cancelled'
      submitValidatedTicket(ticket).then(response => {
        resolve(response)
      })
    })

  }

  const removeTicket = (ticket) => {
    return new Promise((resolve, reject) => {
      store.dispatch('ticket/removeTicket', ticket)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const getTicketIconStatus = (ticket) => {
    if (ticket.status == 'New') {
      return 'hourglass-start'
    } else if (ticket.status == 'In progress') {
      return 'stopwatch'
    } else if (ticket.status == 'Done') {
      return 'check'
    } else if (ticket.status == 'Cancelled') {
      return 'trash-alt'
    }
  }

  const getTicketColorStatus = (ticket) => {
    if (ticket.status == 'New') {
      return 'secondary'
    } else if (ticket.status == 'In progress') {
      return 'primary'
    } else if (ticket.status == 'Done') {
      return 'success'
    } else if (ticket.status == 'Cancelled') {
      return 'danger'
    }
  }

  const getTicketColorPriority = (ticket) => {
    if (ticket.priority == 'Normal') {
      return 'primary'
    } else if (ticket.priority == 'High') {
      return 'warning'
    } else if (ticket.priority == 'Urgent') {
      return 'danger'
    }
  }

  return {
    submitValidatedTicket,
    nextStep,
    cancelTicket,
    removeTicket,
    getTicketIconStatus,
    getTicketColorStatus,
    getTicketColorPriority
  }
}