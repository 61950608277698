<template>
  <generic-content
      :isLinkable="false"
      :isEditable="ticket.status == 'New'"
      :isDownloadable="false"
      :isDeletable="ticket.status == 'New'"
      @click="$emit('click')"
      @edit="$emit('edit')"
      @delete="$emit('delete')"
  >
    <template #iconContent>
      <b-avatar
          size="32"
          variant="light-primary"
          class="my-auto"
      >
        <icon
            :class="'text-'+getTicketColorStatus(ticket)"
            :icon="getTicketIconStatus(ticket)"
        />
      </b-avatar>
    </template>

    <template #bodyContent>
      <h6 class="transaction-title text-truncate">
        {{ ticket.title }}
      </h6>
      <div class="d-flex justify-content-between text-truncate">
        <small>
          <i>{{ $t(ticket.type) }}</i>
        </small>
        <b-badge
            pill
            :variant="getTicketColorPriority(ticket)"
        >
          <small>{{ $t(ticket.priority) }}</small>
        </b-badge>
      </div>
    </template>

  </generic-content>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { useTickets } from '../../../views/support/tickets/useTickets'

import GenericContent from './Content'

export default {
  components: {
    GenericContent
  },
  props: {
    ticket: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      getTicketIconStatus,
      getTicketColorStatus,
      getTicketColorPriority,
    } = useTickets()

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed

      // Methods
      getTicketIconStatus,
      getTicketColorStatus,
      getTicketColorPriority,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {

  },
  created () {
  }
}
</script>

<style lang="scss">

</style>