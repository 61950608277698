import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { useTickets } from './useTickets'
import { capitalize, currency } from '@/utils/filter'
import { getRoute, displayPaymentStatus, getUserData } from '../../../utils/utils'

import i18n from '@/libs/i18n'
import store from '@/store'
import useAPI from '../../../utils/useAPI'
import SearchFilter from '../../../components/searchFilter/SearchFilter'
import ButtonNext from '../../../components/button/Next'
import ButtonEdit from '../../../components/button/Edit'
import ButtonDelete from '../../../components/button/Delete'
import ButtonRemove from '../../../components/button/Remove'
import CardTicket from '../../../components/card/Ticket'

import ModalTicket from '@/components/prompt/Ticket'

export const mixinTickets = {
  components: {
    SearchFilter,
    ModalTicket,
    ButtonNext,
    ButtonEdit,
    ButtonDelete,
    ButtonRemove,
    CardTicket
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const additionalSortOptions = ref([])
    const tableColumns = ref([
      { key: 'number', label: i18n.t('number'), sortable: true },
      { key: 'title', label: i18n.t('title'), sortable: true },
      { key: 'type', label: i18n.t('type'), sortable: true },
      { key: 'priority', label: i18n.t('priority'), sortable: true },
      { key: 'status', label: i18n.t('status'), sortable: true },
    ])

    const ticketModalShow = ref(false)
    const ticketModalTitle = ref(i18n.t('NewTicket'))
    const currentTicket = ref({})
    const submitTicketFormRef = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { tickets } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(ticketModalShow, () => {
      if (ticketModalShow.value == false) {
        resetTicket()
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { fetchTickets } = useAPI()

    const {
      submitValidatedTicket,
      nextStep,
      cancelTicket,
      removeTicket,
      getTicketIconStatus,
      getTicketColorStatus,
      getTicketColorPriority,
    } = useTickets()

    const addTicket = () => {
      ticketModalShow.value = true
    }

    const selectTicket = (ticket) => {
      ticketModalTitle.value = i18n.t('EditTicket')
      currentTicket.value = JSON.parse(JSON.stringify(ticket))

      ticketModalShow.value = true
    }

    const tryCloseTicketModal = (modalEvent) => {
      if (modalEvent.trigger == 'backdrop') {
        // Click outside
        modalEvent.preventDefault()
        submitTicketFormRef.value.click()
      }
    }

    const submitValidatedTicketLocal = () => {
      submitValidatedTicket(currentTicket.value)
        .then(response => {
          ticketModalShow.value = false
        })
    }

    const resetTicket = () => {
      ticketModalTitle.value = i18n.t('NewTicket')
      currentTicket.value = JSON.parse(JSON.stringify(store.getters['ticket/getEmptyTicket']))
    }

    const removeTicketLocal = (ticket) => {
      removeTicket(ticket)
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    if (getRoute().name == "Tickets") {
      fetchTickets()
    }

    resetTicket()

    return {
      // Components
      capitalize,
      currency,
      displayPaymentStatus,
      getUserData,

      // Data
      additionalSortOptions,
      tableColumns,
      ticketModalShow,
      ticketModalTitle,
      currentTicket,
      submitTicketFormRef,

      // Computed
      tickets,

      // Methods
      getTicketIconStatus,
      getTicketColorStatus,
      addTicket,
      selectTicket,
      nextStep,
      cancelTicket,
      tryCloseTicketModal,
      submitValidatedTicketLocal,
      removeTicketLocal,
      getTicketColorPriority,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    deleteTicketAlert (ticket) {
      this.$bvModal
        .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theTicket') }), {
          okVariant: 'danger',
          okTitle: this.capitalize(this.$t('delete')),
          cancelVariant: 'outline-secondary',
          cancelTitle: this.capitalize(this.$t('cancel')),
          centered: true,
        })
        .then(value => {
          if (value == true) {
            this.removeTicketLocal(ticket)
          }
        })
    }
  },
  mounted () {
  },
  created () {
  }
}