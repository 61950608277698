<template>
  <div>
    <search-filter
        :items="tickets"
        keyTitleForAlphabeticalSort="title"
        :additionalSortOptions="additionalSortOptions"
        :selectedView="'list'"
        :excludedKeys="['ticket']"

        :tableColumns="tableColumns"
        :isActionColumnEnabled="$can('manage', 'all')"
        :allowAdd="true"
        @columnClick="$can('manage', 'all')?selectTicket($event):''"
        @addItem="ticketModalShow=true"
    >
      <!--            Grid view-->
      <template v-slot:gridView="{item}">
        <card-ticket
            :ticket="item"
            @click="selectTicket(item)"
            @edit="selectTicket(item)"
            @delete="deleteTicketAlert(item)"
        />
      </template>


      <!--      List view-->

      <!--      Cell type-->
      <template #listView_cell_type="{item}">
        {{ $t(item.type) }}
      </template>

      <!--      Cell priority-->
      <template #listView_cell_priority="{item}">
        <b-badge
            pill
            :variant="getTicketColorPriority(item)"
        >
          <small>{{ $t(item.priority) }}</small>
        </b-badge>
      </template>

      <!--      Cell status-->
      <template #listView_cell_status="{item}">
        <icon
            :class="'text-'+getTicketColorStatus(item)"
            v-b-tooltip.hover.left="capitalize($t(item.status))"
            :icon="getTicketIconStatus(item)"
        />
      </template>

      <!--      Actions-->
      <template #listView_cell_actions="{item}">
        <div class="text-nowrap">

          <!--          Next step-->
          <button-next
              v-if="getUserData().id == 1 && item.status != 'Done'"
              @click.native="nextStep(item)"
              v-b-tooltip.hover.left="capitalize($t('NextStep'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Cancel-->
          <button-delete
              v-if="getUserData().id == 1 && item.status != 'Done' && item.status != 'Cancelled'"
              @click.native.stop="cancelTicket(item)"
              v-b-tooltip.hover.left="capitalize($t('cancel'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Edit-->
          <button-edit
              v-if="item.status == 'New'"
              @click.native="selectTicket(item)"
              v-b-tooltip.hover.left="capitalize($t('Edit'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />
          <!--          Delete-->
          <button-remove
              v-if="item.status == 'New'"
              @click.native.stop="deleteTicketAlert(item)"
              v-b-tooltip.hover.left="capitalize($t('delete'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />


        </div>
      </template>

    </search-filter>

    <!-- modal ticket -->
    <modal-ticket
        :ticket.sync="currentTicket"
        :title="ticketModalTitle"
        :isOpen.sync="ticketModalShow"
        @submitValidated="submitValidatedTicketLocal"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { mixinTickets } from './mixinTickets'

export default {
  components: {},
  mixins: [mixinTickets],
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>