<template>
  <b-row>
    <!--    Title-->
    <b-col md="12">
      <field-input
          name="title"
          rules="required"
          :model.sync="ticket.title"
          :disabled="readOnly"
      />
    </b-col>

    <!--    Type-->
    <b-col md="4">
      <field-select
          name="type"
          :model.sync="ticket.type"
          :options="ticketTypes"
          label="label"
          reduce="value"
          :isRequired="true"
          :clearable="false"
          :disabled="readOnly"
      />
    </b-col>

    <!--    Priority-->
    <b-col md="4">
      <field-select
          name="priority"
          :model.sync="ticket.priority"
          :options="ticketPriorities"
          label="label"
          reduce="value"
          :clearable="false"
          :isRequired="true"
          :disabled="readOnly"
      />
    </b-col>

    <!--    Module-->
    <b-col md="4">
      <field-select
          name="module"
          :model.sync="ticket.module"
          :options="ticketModules"
          label="label"
          reduce="value"
          :disabled="readOnly"
      />
    </b-col>

    <!--    Files-->
    <b-col md="12">
      <b-row>
        <b-col
            v-if="!readOnly"
            md="6"
        >
          <field-file
              :singleFile.sync="newFile"
              documentType="ticket"
              :resetAfterUploaded="true"
          />
        </b-col>

        <b-col :md="readOnly?12:6">
          <b-card
              class="card-transaction"
              no-body
          >

            <b-card-body>
              <content-document
                  v-for="(file, index) in ticket.files"
                  :document="file"
                  :is-deletable="!readOnly"
                  @delete="deleteFile(index)"
              />

            </b-card-body>
          </b-card>

        </b-col>

      </b-row>
    </b-col>

    <!--    Content-->
    <b-col md="12">

      <field-textarea
          :model.sync="ticket.content"
          name="content"
          placeholder="content"
          :isRequired="true"
          :disabled="readOnly"
      />
    </b-col>


  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import FieldInput from '../input/Input'
import FieldSelect from '../input/Select'
import FieldTextarea from '../input/Textarea'
import ContentDocument from '../card/components/DocumentContent'
import FieldFile from '../input/File'
import i18n from '@/libs/i18n'

export default {
  components: {
    FieldInput,
    FieldSelect,
    FieldTextarea,
    ContentDocument,
    FieldFile
  },
  props: {
    ticket: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const ticketTypes = ref([
      {
        label: i18n.t('Issue'),
        value: 'Issue'
      },
      {
        label: i18n.t('Question'),
        value: 'Question'
      }
    ])
    const ticketModules = ref([
      {
        value: 'Home',
        label: i18n.t('Home')
      },
      {
        value: 'Contacts',
        label: i18n.t('Contacts')
      },
      {
        value: 'Indicators',
        label: i18n.t('Indicators')
      },
      {
        value: 'Orders',
        label: i18n.t('Orders')
      },
      {
        value: 'Incoming invoices',
        label: i18n.t('Incoming invoices')
      },
      {
        value: 'Workflow',
        label: i18n.t('Workflow')
      },
      {
        value: 'Offers',
        label: i18n.t('Offers')
      },
      {
        value: 'Outgoing invoices',
        label: i18n.t('OutgoingInvoices')
      },
      {
        value: 'Banking flows',
        label: i18n.t('BankingFlows')
      },
      {
        value: 'Customer receivable',
        label: i18n.t('CustomerReceivable')
      },
      {
        value: 'Supplier receivable',
        label: i18n.t('SupplierReceivable')
      },
      {
        value: 'Employees',
        label: i18n.t('Employees')
      },
      {
        value: 'Salaries',
        label: i18n.t('Salaries')
      },
      {
        value: 'Holidays',
        label: i18n.t('Holidays')
      },
      {
        value: 'Social security contributions',
        label: i18n.t('SocialSecurityContributions')
      },
    ])
    const ticketPriorities = ref([
      {
        label: i18n.t('NormalE'),
        value: 'Normal'
      },
      {
        label: i18n.t('High'),
        value: 'High'
      },
      {
        label: i18n.t('Urgent'),
        value: 'Urgent'
      }
    ])
    const newFile = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const readOnly = computed(() => {
      if (props.ticket.status == 'New') {
        return false
      } else {
        return true
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(newFile, (val) => {
      if (val != null) {
        props.ticket.files.splice(props.ticket.files.length, 0, val)
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const deleteFile = (index) => {
      props.ticket.files.splice(index, 1)
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------

    return {
      // Components

      // Data
      ticketTypes,
      ticketModules,
      ticketPriorities,
      newFile,

      // Computed
      readOnly,

      // Methods
      deleteFile,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>