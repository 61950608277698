<template>
  <b-modal
      id="1"
      :title="title"
      v-model="isOpenLocal"
      size="lg"
      scrollable
      hide-footer
      @hide="tryCloseTicketModal"
  >
    <b-card>
      <validation-observer #default="{handleSubmit}">
        <b-form
            @keydown.enter.stop.prevent=""
            @submit.prevent="handleSubmit(submitValidatedTicketLocal)">
          <form-ticket :ticket="ticketLocal" />
          <button ref="submitTicketFormRef"
                  class="d-none"></button>
        </b-form>
      </validation-observer>
    </b-card>
  </b-modal>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'

import FormTicket from '../form/Ticket'

export default {
  components: {
    ValidationObserver,
    FormTicket
  },
  props: {
    ticket: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isOpenLocal = ref(false)
    const ticketLocal = ref(props.ticket)
    const submitTicketFormRef = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(isOpenLocal, (val) => {
      emit('update:isOpen', val)
    })
    watch(ticketLocal, (val) => {
      emit('update:ticket', val)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const tryCloseTicketModal = (modalTicket) => {
      if (modalTicket.trigger == 'backdrop') {
        // Click outside
        modalTicket.preventDefault()
        submitTicketFormRef.value.click()
      }
    }

    const submitValidatedTicketLocal = () => {
      emit('submitValidated')
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      isOpenLocal,
      ticketLocal,
      submitTicketFormRef,

      // Computed

      // Methods
      tryCloseTicketModal,
      submitValidatedTicketLocal
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    ticket: function (val) {
      this.ticketLocal = val
    },
    isOpen: function (val) {
      this.isOpenLocal = val
    }
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>